import React, { createContext, useContext } from 'react';
import Head from 'next/head'; // Importar o Head do Next.js
import i18n from 'utils/i18n';

const ContactDetailsContext = createContext();

function ContactDetailsProvider({ children }) {
  const t = i18n.useTranslations('jsonLdContactInfo');
  const jsonLdAnswer = `${t('json_ld_answer')} <a href="${t('json_ld_answer_anchor_link')}">${t(
    'json_ld_answer_anchor_label'
  )}</a>`;

  const jsonLdData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: t('json_ld_question_one'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: jsonLdAnswer
        }
      },
      {
        '@type': 'Question',
        name: t('json_ld_question_two'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: jsonLdAnswer
        }
      },
      {
        '@type': 'Question',
        name: t('json_ld_question_three'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: jsonLdAnswer
        }
      },
      {
        '@type': 'Question',
        name: t('json_ld_question_four'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: jsonLdAnswer
        }
      }
    ]
  });

  return (
    <ContactDetailsContext.Provider>
      <Head>
        <script id="application/ld+json" type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLdData }} />
      </Head>
      {children}
    </ContactDetailsContext.Provider>
  );
}

function useContactDetails() {
  const context = useContext(ContactDetailsContext);
  if (context === undefined) {
    throw new Error('useContactDetails must be used within a ContactDetailsProvider');
  }

  return context;
}

export { ContactDetailsProvider, ContactDetailsContext, useContactDetails };
